import { combineReducers } from 'redux'

// Import custom components
import headerReducer from './headerReducer'
import assetReducer from './assetReducer'
import assetUploadReducer from './assetUploadReducer'
import crmReducer from './crmReducer'
import setupReducer from "./setupReducer";
import conversionReducer from "./conversionReducer";
import campaignReducer from './campaignReducer'
import businessReducer from './businessReducer'

// Root Reducer
const rootReducer = combineReducers({
  headerTopMenus: headerReducer,
  assetLibrary: assetReducer,
  assetUpload: assetUploadReducer,
  crmLeads: crmReducer,
  setupScreen: setupReducer,
  conversionData: conversionReducer,
  campaign: campaignReducer,
  mybusiness: businessReducer
});

export default rootReducer
