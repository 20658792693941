/* eslint-disable */
import { useRef, useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button, Modal, FormGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import FileInputComponent from "react-file-input-previews-base64";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Toast from "../../../shared/Toast";
import Multiselect from "multiselect-react-dropdown";
import vImgUrl from "../../../../assets/video-img.png";
import pImgUrl from "../../../../assets/pdf-img.png";
import micrositeVideo from "../../../../assets/asset-upload/video.png";
import micrositeBroucher from "../../../../assets/asset-upload/brochures.png";

import CropImage from "./CropImage";
import "./editassets.css"
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  saveGlobalFieldsData,
  setBreadcrumbStatus,
  fetchAssetLibraryHashtagList,
  fetchCampaignList,
  fetchGreetingList,
  fetchLandingpageUrls,
  fetchShowcaseUrls,
  fetchMicrositeUrls,
  fetchContentTypeList,
  editContent,
  fetchAssetLibrarySubCategoryList,
  fetchAssetLibraryLeadGenerationList,
  addNewHashTag,
  fetchPersonalizeList,
  addNewTypePersonalize,
  updateMainContent,
  deleteNewTypePersonalize,
} from "../../../../redux/actions/actions";

const Edit = (props) => {
  const authToken = props.authToken;

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const fileInputRef = useRef(null);
  const nameRef = useRef();
  const longCaptionRef = useRef();
  const shortCaptionRef = useRef();
  const cusRef = useRef();
  const msRef = useRef();
  const descRef = useRef();

  // const [title, setTitle] = useState("");
  const [platformFB, setPlatformFB] = useState(false);
  const [buyNowCheck, setBuyNowCheck] = useState(false);
  const [platformTW, setPlatformTW] = useState(false);
  const [platformLN, setPlatformLN] = useState(false);
  const [platform, setPlatform] = useState([]);
  const [urlOption, setUrlOption] = useState("L");
  const [ctaCheck, setCtaCheck] = useState(false);
  const [lpUrl, setLpUrl] = useState(false);
  const [scUrl, setScUrl] = useState(false);
  const [msUrl, setMsUrl] = useState(false);
  const [cusUrl, setCusUrl] = useState(false);
  const [noneUrl, setNoneUrl] = useState(false);
  const [formType, setFormType] = useState("");
  
  const [personalizeModal, setPersonalizeModal] = useState(false);
  const [personalizeType, setPersonalizeType] = useState("");
  const [contentUpdatePopup, setContentUpdatePopup] = useState(false);
  const [personalizeDeleteModal, setPersonalizeDeleteModal] = useState(false);
  const [personalizeName, setPersonalizeName] = useState("");
  const [selectedPersonalize, setSelectedPersonalize] = useState(null);
  const [personalizeFileData, setPersonalizeFileData] = useState(null);
  const [uploadedEditData, setUploadedEditData] = useState({});
  const [personalizeFileName, setPersonalizeFileName] = useState("");
  const [fileMainLink, setFileMainLink] = useState("");
  const [selectedPersonalizeCheck, setSelectedPersonalizeCheck] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
  const [selectedHashtagList, setSelectedHashtagList] = useState([]);
  const [selectedCampaignList, setSelectedCampaignList] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);
  const [leadGeneration, setLeadGeneration] = useState("");
  let [contentCustomUrl, setcontentCustomUrl] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const [selectedVideoType, setSelectedVideoType] = useState(null);
  const [audioFile, setAudioFile] = useState({});
  const [audioName, setAudioName] = useState("");
  // microsite and mobile app thumbnail
  const [mobileAppCheck, setMobileAppCheck] = useState(true);
  const [micrositeCheck, setMicrositeCheck] = useState(false);
  const [mobileAppImage, setMobileAppImage] = useState(null);
  const [micrositeImage, setMicrositeImage] = useState(null);
  const [openMicrositeCropper, setOpenMicrositeCropper] = useState(true);
  const [micrositeCropperData, setMicrositeCropperData] = useState(null);
  const [micrositeCropperImageName, setMicrositeCropperImageName] = useState("");
  const [currentThumbnailStatus, setCurrentThumbnailStatus] = useState("mobile_app");


  const [activeAsset, setActiveAsset] = useState(0);
  const [activeAssetData, setActiveAssetData] = useState([]);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const micrositeUrl = useSelector((state) => state.assetUpload.micrositeUrl);
  const customUrl = useSelector((state) => state.assetUpload.customUrl);
  const main_content_link = useSelector((state) => state.assetLibrary.main_content_link);
  const landingSecureUrl = useSelector((state) => state.assetUpload.landingSecureUrl);
  const showcaseSecureUrl = useSelector((state) => state.assetUpload.showcaseSecureUrl);
  const [activeAssetTitle, setActiveAssetTitle] = useState(null);
  const [activeAssetDesc, setActiveAssetDesc] = useState(null);
  const [activeAssetLongDesc, setActiveAssetLongDesc] = useState(null);
  const [activeAssetShortDesc, setActiveAssetShortDesc] = useState(null);
  const [activeAssetCatogries, setActiveAssetCatogries] = useState(null);
  const [activeAssetSubCatogries, setActiveAssetSubCatogries] = useState(null);
  const [activeAssetCampaigns, setActiveAssetCampaigns] = useState(null);
  const [activeAssetHashtags, setActiveAssetHashtags] = useState(null);
  const [activeAssetDefineUrl, setActiveAssetDefineUrl] = useState(null);
  // const [activeAssetData, setSelectedHashtags] = useState([]);

  const [newThumbImg, setNewThumbImg] = useState(null);
  const [newCategory, setNewCategory] = useState([]);
  const [newSubCategory, setNewSubCategory] = useState([]);
  const [newHashtag, setNewHashtag] = useState([]);

  const [tagName, setTagName] = useState("");
  const [tagModal, setTagModal] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);
  const hiddenFileInput1 = useRef(null);
  const [activeAssetThumbnail, setActiveAssetThumbnail] = useState("");
  const videoImgUrl = vImgUrl;
  const pdfImgUrl = pImgUrl;

  let secureUrl = null;
  //let newThumbImg = null;
  //let newCategory = [];
  //let newHashtag = [];

  const isLoading = useSelector((state) => state.assetUpload.loading);
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);
  const personalizeList = useSelector((state) => state.assetUpload.personalizeList);
  const video_cta_flag = useSelector((state) => state.conversionData.video_cta_flag);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const baseUploadData = useSelector(
    (state) => state.assetUpload.baseUploadData
  );

  const baseGlobalFields = useSelector(
    (state) => state.assetUpload.globalFieldsData
  );

  const cta_asset_list = useSelector(
    (state) => state.conversionData.cta_asset_list
  );

  const assetSelectTypeList = useSelector(
    (state) => state.assetUpload.selectTypeList
  );

  const editAsset = useSelector(
    (state) => state.assetUpload.editAsset
  );

  const selectedCategories = useSelector(
    (state) => state.assetUpload.selectedCategories
  );

  const selectedSubCategories = useSelector(
    (state) => state.assetUpload.selectedSubCategories
  );

  const selectedCampaigns = useSelector(
    (state) => state.assetUpload.selectedCampaigns
  );

  const selectedHashtags = useSelector(
    (state) => state.assetUpload.selectedHashtags
  );

  const globalCategories = useSelector(
    (state) => state.assetUpload.globalCategories
  );

  const globalCampaigns = useSelector(
    (state) => state.assetUpload.globalCampaigns
  );

  const globalSubCategories = useSelector(
    (state) => state.assetUpload.globalSubCategories
  );

  const globalHashtags = useSelector(
    (state) => state.assetUpload.globalHashtags
  );

  const selectedGreetings = useSelector(
    (state) => state.assetUpload.selectedGreetings
  );

  const selectedDefineUrl = useSelector(
    (state) => state.assetUpload.selectedDefineUrl
  );

  const assetContentList = [useSelector((state) => state.assetLibrary.currentAssetDetails)];
  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );

  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );

  const assetLibrarySubCategoryList = useSelector(
    (state) => state.assetLibrary.subcategoryList
  );

  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );

  const assetLibraryLeadGenerationList = useSelector(
    (state) => state.assetLibrary.leadGenerationList
  );

  //const campaignList = useSelector((state) => state.assetUpload.campaignList);

  const greetingList = useSelector((state) => state.assetUpload.greetingList);

  const business_need_list = useSelector((state) => state.setupScreen.business_need_list);

  const business_flag = useSelector((state) => state.setupScreen.business_flag);

  const landingpageUrlList = useSelector(
    (state) => state.assetUpload.landingpageUrlList
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );

  const contentTypeList = useSelector(
    (state) => state.assetUpload.contentTypeList
  );

  const categories = assetLibraryCategoryList.map(
    ({ id: value, solution_type: label }) => ({
      value,
      label,
    })
  );

  const subcategories = assetLibrarySubCategoryList.map(
    ({ id: value, it_subcat: label }) => ({
      value,
      label,
    })
  );

  const hashtags = assetLibraryHashtagList.map(
    ({ id: value, tag_name: label }) => ({
      value,
      label,
    })
  );

  // const camapigns = campaignList.map(({ c_id: value, camp_name: label }) => ({
  //   value,
  //   label,
  // }));

  let imgUrl;

  if (editAsset) {
    imgUrl = _.find(assetSelectTypeList, (obj) => {
      if (obj.type === selectedAssetType.type) {
        return obj.article_image;
      } else if (selectedAssetType.type === "Document" && obj.type === "Brochure") {
        return obj.article_image;
      } else if (selectedAssetType.type === "Banner" && obj.type === "Banners") {
        return obj.article_image;
      } else if ((selectedAssetType.type === "Social" || selectedAssetType.type === "Image") && (obj.type === "Social Post" || obj.type === "Image")) {
        return obj.article_image;
      }
    });
  }

  props.pageTypeHandler("Global Fields");

  useEffect(() => {
    if ((selectedAssetType.type === "Video" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video")) && uploadedEditData.length > 0) {
      if (uploadedEditData.length > 1) {
        Toast("Please upload single file only.", "E");
        setUploadedEditData({});
      } else if (!(uploadedEditData[0].name).includes(".mp4")) {
        Toast("Please upload .mp4 format file only.", "E");
        setUploadedEditData({});
      } else if (uploadedEditData[0].size > 15360) {
        setUploadedEditData({});
        Toast("Maximum 15MB file size accepted.", "E");
      }
    }
    //  let video = $("#uploadedVideo"); //JQuery selector
    //   console.log("video[0]",video[0]);
    //   console.log("video[0].videoWidth", video[0].videoWidth);
    //   console.log("video[0].videoHeight", video[0].videoHeight); 
    //console.log("uploadedEditData",uploadedEditData);
  }, [uploadedEditData]);
  
  const selectCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedCategoryList(categories);
      activeAssetData.Base.solution_types = categories;
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedCategoryList([]);
      activeAssetData.Base.solution_types = [];
    } else if (event === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.solution_types = value.map(({ value }) => value);
    } else if (value.length === categories.length - 1) {
      setSelectedCategoryList(categories);
      activeAssetData.Base.solution_types = categories;
    } else {
      setSelectedCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.solution_types = value.map(({ value }) => value);
    }
  };


  const selectSubCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedSubCategoryList(subcategories);
      activeAssetData.Base.sub_category = subcategories;
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedSubCategoryList([]);
      activeAssetData.Base.sub_category = [];
    } else if (event === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedSubCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.sub_category = value.map(({ value }) => value);
    } else if (value.length === subcategories.length - 1) {
      setSelectedSubCategoryList(subcategories);
      activeAssetData.Base.sub_category = subcategories;
    } else {
      setSelectedSubCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.sub_category = value.map(({ value }) => value);
    }
  };


  const selectHashtagHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedHashtagList(hashtags);
      activeAssetData.Base.internal_hashtags = hashtags;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedHashtagList([]);
      activeAssetData.Base.internal_hashtags = [];
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedHashtagList(value.map(({ value }) => value));
      activeAssetData.Base.internal_hashtags = value.map(({ value }) => value);
    } else if (value.length === hashtags.length - 1) {
      setSelectedHashtagList(hashtags);
      activeAssetData.Base.internal_hashtags = hashtags;
    } else {
      setSelectedHashtagList(value.map(({ value }) => value));
      activeAssetData.Base.internal_hashtags = value.map(({ value }) => value);
    }
  };

  // const selectCampaignHandler = (value, event) => {
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     setSelectedCampaignList(camapigns);
  //     activeAssetData.Base.campaign = camapigns;
  //   } else if (
  //     event.action === "deselect-option" &&
  //     event.option.value === "*"
  //   ) {
  //     setSelectedCampaignList([]);
  //     activeAssetData.Base.campaign = [];
  //   } else if (event.action === "deselect-option") {
  //     value.filter((o) => o.value !== "*");
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //     activeAssetData.Base.campaign = value.map(({ value }) => value);
  //   } else if (value.length === camapigns.length - 1) {
  //     setSelectedCampaignList(camapigns);
  //     activeAssetData.Base.campaign = camapigns;
  //   } else {
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //     activeAssetData.Base.campaign = value.map(({ value }) => value);
  //   }
  // };

  const cropperFlag = (data) => {
    setOpenMicrositeCropper(data);
  }
  const nextCropper = (fileData) => {
    setMicrositeCropperData(fileData);
  }
  const checkboxHandler = (e, type) => {
    setCurrentThumbnailStatus(type);
    if (type === "mobile_app") {
      if (micrositeImage !== null) {
        setOpenMicrositeCropper(false);
      } else {
        setOpenMicrositeCropper(true);
      }
      setMobileAppCheck(e.target.checked);
      if (e.target.checked === false) {
        if (micrositeCheck === true) {
          setCurrentThumbnailStatus("microsite");
        }
        setMobileAppImage(null);
        setFile(null);
      }
    }
    else if (type === "microsite") {
      setMicrositeCheck(e.target.checked);
      if (e.target.checked === false) {
        if (mobileAppCheck === true) {
          setCurrentThumbnailStatus("mobile_app");
        }
        setMicrositeImage(null);
        setOpenMicrositeCropper(true);
        setMicrositeCropperData(null);
      }
    }
    else {
      console.log("else error")
    }

    if (mobileAppCheck === true && micrositeCheck === false && mobileAppImage === null) {
      setCurrentThumbnailStatus("mobile_app");
    }
  };
  
  const handlePersonalizeThumbnail = (e, count, type) => {
    if (e) {
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
            let data = {
              base64: fr.result,
              file: {
                name: e.target.files[0].name,
                size: e.target.files[0].size,
                type: e.target.files[0].type,
              },
            }
            setPersonalizeFileData(data);
            activeAssetData.Base.personalize_template = data
            setPersonalizeFileName(e.target.files[0].name);
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }
  
  const handleUrlCheckboxChange = (e,obj) => {
      if (selectedPersonalizeCheck.includes(obj)) {
        setSelectedPersonalizeCheck(selectedPersonalizeCheck.filter(item => item !== obj));
      } else {
        setSelectedPersonalizeCheck([...selectedPersonalizeCheck, obj]);
      }
  };
  
  const addPersonalizeName = () => {
    if (personalizeName !== "") {
      dispatch(addNewTypePersonalize(authToken, personalizeName));
      setPersonalizeModal(false);
    } else {
      Toast("Please enter type name", "E");
    }
  }
  
  const deletePersonalizeName = () => {
    if (selectedPersonalizeCheck?.length > 0) {
      selectedPersonalizeCheck.map((key) => {
        dispatch(deleteNewTypePersonalize(authToken, key.id));
      })
      setSelectedPersonalizeCheck([]);
      setPersonalizeDeleteModal(false);
    } else {
      Toast("Please select type name", "E");
    }
  }

  const platformHandler = (e, type) => {
    let _updatedContent = _.clone(activeAssetData.Base.platform);
    if (e.target.checked) {
      if (type === "fb") {
        _updatedContent.push(1);
      } else if (type === "tw") {
        _updatedContent.push(2);
      } else if (type === "in") {
        _updatedContent.push(3);
      }
    } else {
      if (type === "fb") {
        _updatedContent = _updatedContent.filter((item) => item !== 1);
      } else if (type === "tw") {
        _updatedContent = _updatedContent.filter((item) => item !== 2);
      } else if (type === "in") {
        _updatedContent = _updatedContent.filter((item) => item !== 3);
      }
    }
    activeAssetData.Base.platform = _updatedContent;
    setPlatform(_updatedContent);
  };

  const handleButtonClick = event => {
    activeAssetData.Base.thumb_image = "";
    if (mobileAppCheck === true || micrositeCheck === true) {
      if (currentThumbnailStatus === "mobile_app" && mobileAppCheck === true) {
        hiddenFileInput.current.click();
      } else if (currentThumbnailStatus === "microsite" && micrositeCheck === true) {
        setOpenMicrositeCropper(true);
        setMicrositeCropperData(null);
        hiddenFileInput1.current.click();
      } else {
        return;
      }
    } else {
      Toast("Select the platform to deploy", "E");
    }
  };
  
  const handlePersonalizeTempName = (str) => {
    return str?.slice(str.lastIndexOf("/")+1);
  }
  
  const handleMultipleImage = (e, count, type) => {
    if (e) {
      if (mobileAppCheck === true) {
        setFile(null);
        setFileName("");
      }
      if (micrositeCheck === true) {
        setMicrositeCropperData(null);
        setMicrositeCropperImageName("");
      }

      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282 && currentThumbnailStatus !== "microsite") {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else if (imgWidth !== 353 && imgHeight !== 400 && currentThumbnailStatus === "microsite") {
            setMicrositeCropperData(fr.result);
            setMicrositeCropperImageName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          }
          else {
            setCurrentThumbnailStatus(type);
            setFileName(e.target.files[0].name);
            setMicrositeCropperImageName(e.target.files[0].name);
            submitedMultipleImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }

  const submitedMultipleImage = (e, path, name) => {
    if (activeAssetData) {
      let blob = new Blob();
      if (currentThumbnailStatus === "mobile_app") {
        const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
        activeAssetData.Base.thumbImg = {
          base64: e,
          file: {
            name: fileee.name,
            size: fileee.size,
            type: fileee.type,
          },
        };
        setMobileAppImage(activeAssetData.Base.thumbImg);
        setCurrentThumbnailStatus("microsite");
        setFile(null); setFileName("");
      } else if (currentThumbnailStatus === "microsite") {
        const mFileee = new File([e], path === "direct" ? name : micrositeCropperImageName, { type: "image/jpeg" });
        activeAssetData.Base.micrositeThumbImg = {
          base64: e,
          file: {
            name: mFileee.name,
            size: mFileee.size,
            type: mFileee.type,
          },
        };
        setMicrositeImage(activeAssetData.Base.micrositeThumbImg)
        setCurrentThumbnailStatus("none");
        setMicrositeCropperData(null); setMicrositeCropperImageName("");
      } else {
        console.log("error");
      }
    }
  }
  
  const handleContentSubmit = () => {
    let payloadData;
    let updloadPayloadData;
    if (uploadedEditData?.length === 0 || Object.keys(uploadedEditData)?.length === 0) {
      Toast("Please uploaded file", "E");
      return;
    }
    console.log("uploadedEditData", uploadedEditData);
    if (selectedAssetType.type === "Social" || selectedAssetType.type === "Image") {
      updloadPayloadData = {
        base64: uploadedEditData?.base64,
        file: {
          name: uploadedEditData?.name,
          size: uploadedEditData?.size,
          type: uploadedEditData?.type,
        }
      }
      payloadData = {
        content_type: activeAssetData?.Base?.type,
        content_id : activeAssetData?.Base?.content_id,
        post_image: updloadPayloadData
      }
      
    }
    else if (selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Document" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic") {
      
      updloadPayloadData = {
        base64: uploadedEditData?.base64,
        file: {
          name: uploadedEditData?.name,
          size: uploadedEditData?.size,
          type: uploadedEditData?.type,
        }
      }
      payloadData = {
        content_type: activeAssetData?.Base?.type,
        content_id : activeAssetData?.Base?.content_id,
        document: updloadPayloadData
      }
      
    } else if (selectedAssetType.type === "Microsite") {
      if (activeAssetData?.Base?.c_type === "brochure") {
        
          updloadPayloadData = {
            base64: uploadedEditData?.base64,
            file: {
              name: uploadedEditData?.name,
              size: uploadedEditData?.size,
              type: uploadedEditData?.type,
            }
          }
          payloadData = {
            content_type: activeAssetData?.Base?.type,
            content_id: activeAssetData?.Base?.content_id,
            c_type: "brochure",
            content_file: updloadPayloadData
        }
        
      } else {
        
          updloadPayloadData = {
            base64: uploadedEditData[0]?.base64,
            file: {
              name: uploadedEditData[0]?.name,
              size: uploadedEditData[0]?.size,
              type: uploadedEditData[0]?.type,
              height: uploadedEditData[0]?.height,
              width: uploadedEditData[0]?.width
            }
          }
          payloadData = {
            content_type: activeAssetData?.Base?.type,
            content_id: activeAssetData?.Base?.content_id,
            c_type: "video",
            content_file: updloadPayloadData
        }
        
      }    
    } else if (selectedAssetType.type === "Video") {
      
      updloadPayloadData = {
        base64: uploadedEditData[0]?.base64,
        file: {
          name: uploadedEditData[0]?.name,
          size: uploadedEditData[0]?.size,
          type: uploadedEditData[0]?.type,
          height: uploadedEditData[0]?.height,
          width: uploadedEditData[0]?.width
        }
      }
      payloadData = {
        content_type: activeAssetData?.Base?.type,
        content_id : activeAssetData?.Base?.content_id,
        video: updloadPayloadData
      }
      
    } else if (selectedAssetType?.type === "Banner") {
      
      updloadPayloadData = {
        base64: uploadedEditData?.base64,
        file: {
          name: uploadedEditData?.name,
          size: uploadedEditData?.size,
          type: uploadedEditData?.type,
        }
      }
      payloadData = {
        content_type: activeAssetData?.Base?.type,
        content_id : activeAssetData?.Base?.content_id,
        banner_image: updloadPayloadData
      }
      
    } else {
      console.log("content_type missing");
    }
    dispatch(updateMainContent(props.authToken, payloadData, onSuccess));
  }
  
  const onSuccess = () => {
    setContentUpdatePopup(false)
  }
  
  const submitHandler = async (e) => {
    e.preventDefault();
    let globalFields;
    let updateData;
    if (selectedAssetType.type === "Social" || selectedAssetType.type === "Image") {
      updateData = {
        data: [{
          Base: {
            thumbImg: (newThumbImg) ? newThumbImg : {},
            buy_now: activeAssetData?.Base?.buy_now,
            business_need: business_flag === true ? parseInt(selectedBusinessType) : "",
            post_image: {},
            content_name: activeAssetData.Base.type,
            post_type: 1,
            content_id: activeAssetData.Base.content_id,
            platform: activeAssetData.Base.platform,
            status: "Draft",
            title: activeAssetData.Base.content_title,
            multi_cateSolutions: (_.has(activeAssetData.Base.solution_types[0], 'id')) ? newCategory : activeAssetData.Base.solution_types,
            //campaign: selectedCampaignList,
            sub_category: (_.has(activeAssetData.Base.sub_category[0], 'id')) ? newSubCategory : activeAssetData.Base.sub_category,
            lg_type: activeAssetData.Base.lg_type,
            intrltags: (_.has(activeAssetData.Base.internal_hashtags[0], 'id')) ? newHashtag : activeAssetData.Base.internal_hashtags,
            long_caption: activeAssetData.Base.long_caption,
            short_caption: ((!platformTW && !platformLN) ? "" : activeAssetData.Base.short_caption),
            
            secure_url: (activeAssetData.Base.url_option === "M") ? micrositeUrl : activeAssetData.Base.secure_url || activeAssetData.Base.social_posturl ,
            url_option: activeAssetData.Base.url_option,
            greeting_id: activeAssetData.Base.greeting_id,
          }
        }]
      };
    } else if (
      selectedAssetType.type === "Brochure" ||
      (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "brochure") ||
      selectedAssetType.type === "Document" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic"
    ) {
      updateData = {
        data: [{
          Base: {
            thumbImg: (mobileAppImage) ? mobileAppImage : {},
            buy_now: activeAssetData?.Base?.buy_now,
            business_need: business_flag === true ? parseInt(selectedBusinessType) : "",
            document: {},
            content_name: activeAssetData.Base.type,
            doc_type: 2,
            content_id: activeAssetData.Base.content_id,
            content_type: parseInt(activeAssetData.Base.type_id),
            status: "Draft",
            micrositeThumbImg: (micrositeImage) ? micrositeImage : {},
            title: activeAssetData.Base.content_title,
            multi_cateSolutions: (_.has(activeAssetData.Base.solution_types[0], 'id')) ? newCategory : activeAssetData.Base.solution_types,
            //campaign: selectedCampaignList,
            sub_category: (_.has(activeAssetData.Base.sub_category[0], 'id')) ? newSubCategory : activeAssetData.Base.sub_category,
            intrltags: (_.has(activeAssetData.Base.internal_hashtags[0], 'id')) ? newHashtag : activeAssetData.Base.internal_hashtags,
            description: activeAssetData.Base.description,
          }
        }]
      };
    } else if (selectedAssetType.type === "Video"  || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video")) {
      updateData = {
        data: [{
          Base: {
            thumbImg: (mobileAppImage) ? mobileAppImage : {},
            buy_now: activeAssetData?.Base?.buy_now,
            business_need: business_flag === true ? parseInt(selectedBusinessType) : "",
            video: {},
            content_name: activeAssetData.Base.type,
            post_type: 1,
            p_type: activeAssetData.Base.p_type,
            reel: audioFile,
            audio_duration: audioDuration,
            content_id: activeAssetData.Base.content_id,
            platform: activeAssetData.Base.platform,
            personalize_id: company_logo?.personalize_video === 1 ? activeAssetData.Base.personalize_id : "",
            personalize_template: company_logo?.personalize_video === 1 ? (activeAssetData.Base.personalize_id ? (personalizeFileData ? personalizeFileData : {}) : "") : "" ,
            status: "Draft",
            form_type: video_cta_flag === true && formType !== null ? formType : null,
            title: activeAssetData.Base.content_title,
            video_type: video_cta_flag === true ? parseInt(selectedVideoType) : null,
            micrositeThumbImg: (micrositeImage) ? micrositeImage : {},
            multi_cateSolutions: (_.has(activeAssetData.Base.solution_types[0], 'id')) ? newCategory : activeAssetData.Base.solution_types,
            //campaign: selectedCampaignList,
            sub_category: (_.has(activeAssetData.Base.sub_category[0], 'id')) ? newSubCategory : activeAssetData.Base.sub_category,
            lg_type: activeAssetData.Base.lg_type,
            intrltags: (_.has(activeAssetData.Base.internal_hashtags[0], 'id')) ? newHashtag : activeAssetData.Base.internal_hashtags,
            description: activeAssetData.Base.description,
            secure_url: (activeAssetData.Base.url_option === "M") ? micrositeUrl : activeAssetData.Base.secure_url,
            url_option: activeAssetData.Base.url_option,
            greeting_id: activeAssetData.Base.greeting_id,
          }
        }]
      };
    } else if (selectedAssetType.type === "Banner") {
      updateData = {
        data: [{
          Base: {
            thumbImg: (newThumbImg) ? newThumbImg : {},
            business_need: business_flag === true ? parseInt(businessType) : "",
            banner_image: {},
            content_name: activeAssetData.Base.type,
            content_id: activeAssetData.Base.content_id,
            status: "Draft",
            title: activeAssetData.Base.content_title,
            description: activeAssetData.Base.description,
            secure_url: secureUrl, /// Secure URL - hidden - if none-empty //LP & SC only
            url_option: urlOption,
          }
        }]
      };
    }

    // console.log("updateData>>", updateData);

    if (updateData.data[0].Base.content_name === "Social" || updateData.data[0].Base.content_name === "Image" ||
      updateData.data[0].Base.content_name === "Video" ||
      (updateData.data[0].Base.content_name === "Microsite" && activeAssetData?.Base?.c_type === "video")) {

      dispatch(editContent(props.authToken, updateData));
      setTimeout(() => {
        navigate("/AssetLibrary");
      }, 2000);

      // if (updateData.data[0].Base.content_title) {
      //   dispatch(editContent(props.authToken, updateData));
      //   setTimeout(() => {
      //     navigate("/AssetLibrary");
      //   }, 2000);
      // } else {
      //   Toast("Please enter mandatory fields.", "E");
      // }

    } else if (updateData.data[0].Base.content_name === "Brochure" ||
      (updateData.data[0].Base.content_name === "Microsite" && activeAssetData?.Base?.c_type === "brochure") ||
      updateData.data[0].Base.content_name === "Document" ||
      updateData.data[0].Base.content_name === "Infographics" ||
      updateData.data[0].Base.content_name === "Infographic") {

      dispatch(editContent(props.authToken, updateData));
      setTimeout(() => {
        navigate("/AssetLibrary");
      }, 2000);
      // if (updateData.data[0].Base.content_title) {

      //   dispatch(editContent(props.authToken, updateData));
      //   setTimeout(() => {
      //     navigate("/AssetLibrary");
      //   }, 2000);
      // } else {
      //   Toast("Please enter mandatory fields.", "E");
      // }

    } else if (updateData.data[0].Base.content_name === "Banner") {

      dispatch(editContent(props.authToken, updateData));
      setTimeout(() => {
        navigate("/AssetLibrary");
      }, 2000);

    }

  };
  
  useEffect(() => {
    setFileMainLink(main_content_link);
  }, [main_content_link])
  

  const activeAssetHandler = (index) => {
    setActiveAsset(index);
    setActiveAssetData(baseGlobalFields[index]);
  };

  const setActiveCategories = () => {
    let currCategory = [];
    if (baseGlobalFields[0]?.Base && baseGlobalFields[0].Base.solution_types.length > 0) {
      baseGlobalFields[0].Base.solution_types.some((c) => {
        currCategory.push({ value: c.id, label: c.it_type });
      });
      setActiveAssetCatogries(currCategory);
    }
  }

  const setActiveSubCategories = () => {
    let currSubCategory = [];
    activeAssetData?.Base?.sub_category.some((c) => {
      subcategories.some((b) => {
        if (b.value == +c) {
          currSubCategory.push(b);
        }
      })
    });
    setActiveAssetSubCatogries(currSubCategory);
  }

  // const setActiveCategories1 = () => {
  //   let currCategory = [];
  //   if(activeAssetData.Base && activeAssetData.Base.solution_types.length > 0) {
  //       activeAssetData.Base.solution_types.some((c)=>{
  //           currCategory.push({value: c.id, label: c.it_type});
  //       });
  //       setActiveAssetCatogries(currCategory);
  //   }
  // }

  // const setActiveCampaigns = () => {
  //   let currCampaign = [];
  //   activeAssetData.Base.campaign.some((c)=>{
  //     camapigns.some((b)=>{
  //       if(b.value === c) {
  //         currCampaign.push(b);
  //       }    
  //     })     
  //   });
  //   setActiveAssetCampaigns(currCampaign);
  // }

  const setActiveHashtags = () => {
    let currHashtag = [];
    if (baseGlobalFields[0] && baseGlobalFields[0]?.Base && baseGlobalFields[0]?.Base?.internal_hashtags.length > 0) {
      baseGlobalFields[0].Base.internal_hashtags.some((h) => {
        if (h !== null) {
          currHashtag.push({ value: h?.id, label: h?.inter_hashtag });
        }
      });
      setActiveAssetHashtags(currHashtag);
    }
  }
  const onSelectCategory = (selectedList, selectedItem) => {
    selectCategoryHandler(selectedList, "select-option");
    //setActiveCategories();
  };

  const onRemoveCategory = (selectedList, removedItem) => {
    selectCategoryHandler(selectedList, "deselect-option");
    //setActiveCategories();
  };

  const onSelectSubCategory = (selectedList, selectedItem) => {
    selectSubCategoryHandler(selectedList, "select-option");
    setActiveSubCategories();
  };

  const onRemoveSubCategory = (selectedList, removedItem) => {
    selectSubCategoryHandler(selectedList, "deselect-option");
    setActiveSubCategories();
  };

  // const onSelectCampaign = (selectedList, selectedItem) => {
  //   selectCampaignHandler(selectedList, "select-option");
  //   setActiveCampaigns();
  // };

  // const onRemoveCampaign = (selectedList, removedItem) => {
  //   selectCampaignHandler(selectedList, "deselect-option");
  //   setActiveCampaigns();
  // };

  const onSelectHashtag = (selectedList, selectedItem) => {
    selectHashtagHandler(selectedList, "select-option");
    // setActiveHashtags();
  };

  const onRemoveHashtag = (selectedList, removedItem) => {
    selectHashtagHandler(selectedList, "deselect-option");
    // setActiveHashtags();
  };

  useEffect(() => {
    dispatch(fetchAssetLibrarySubCategoryList(authToken));
    dispatch(fetchAssetLibraryLeadGenerationList(authToken));
    // dispatch(fetchAssetLibraryHashtagList(authToken));
    // dispatch(fetchCampaignList(authToken));
    // dispatch(fetchGreetingList(authToken));
    // dispatch(fetchLandingpageUrls(authToken));
    // dispatch(fetchShowcaseUrls(authToken));
    // dispatch(fetchMicrositeUrls(authToken));
    // dispatch(fetchContentTypeList(authToken));

    if (_.isEmpty(baseGlobalFields || baseUploadData)) {
      Toast("Uploaded content lost, please re-upload..", "E");
      navigate("/new-asset");
    }

    setActiveAssetData(baseGlobalFields[0]);
    if (baseGlobalFields[0]?.Base?.type === "Social" || baseGlobalFields[0]?.Base?.type === "Banner" || baseGlobalFields[0]?.Base?.type === "Image") {
      setcontentCustomUrl(baseGlobalFields[0]?.Base.social_posturl);
    } else {
      setcontentCustomUrl(baseGlobalFields[0]?.Base.url);
    }
    setActiveAssetThumbnail(baseGlobalFields[0]?.Base?.thumb_image);
    setActiveAssetCatogries(globalCategories);
    setActiveAssetSubCatogries(globalSubCategories);
    //setActiveAssetCampaigns(globalCampaigns); 
    setActiveAssetHashtags(globalHashtags);
    setBuyNowCheck(baseGlobalFields[0]?.Base?.buy_now !== 1 ? false : true)
    // console.log("baseGlobalFields", baseGlobalFields[0]);
    if (!_.isEmpty(baseGlobalFields)) {

      if (baseGlobalFields && baseGlobalFields[0].Base.url) {
        if (baseGlobalFields[0].Base.type === "Video") {
          setActiveAssetDefineUrl((baseGlobalFields[0].Base.url).split("/").pop());
        } else if (baseGlobalFields[0].Base.type === "Social" || baseGlobalFields[0].Base.type === "Banner") {
          setActiveAssetDefineUrl((baseGlobalFields[0].Base.social_posturl).split("/").pop());
        }
      }
      setActiveAssetTitle(baseGlobalFields[0].Base.content_title);
      setActiveCategories();
      setActiveSubCategories();
      setActiveHashtags();


      if (baseGlobalFields && baseGlobalFields.length > 0 && baseGlobalFields[0].Base?.type !== 'Banner') {
        if (baseGlobalFields[0].Base?.solution_types.length > 0) {
          let currCategory = [];
          baseGlobalFields[0].Base.solution_types.map((c) => {
            currCategory.push(c.id);
          })
          setNewCategory(currCategory);
        }

        if (baseGlobalFields[0]?.Base?.sub_category.length > 0) {
          let currSubCategory = [];
          baseGlobalFields[0].Base.sub_category.map((c) => {
            currSubCategory.push(c.id);
          })
          setNewSubCategory(currSubCategory);
        }

        if (baseGlobalFields[0] && baseGlobalFields[0]?.Base?.internal_hashtags.length > 0) {
          let currHashtag = [];
          baseGlobalFields[0]?.Base?.internal_hashtags.map((c) => {
            if (c !== null) {
              currHashtag.push(c?.id);
            }
          })
          setNewHashtag(currHashtag);
        }
      }

      if (baseGlobalFields && baseGlobalFields[0].Base.url_option) {
        switch (baseGlobalFields[0].Base.url_option) {
          case "L":
            return setLpUrl(true);
          case "V":
            return setCtaCheck(true);
          case "S":
            return setScUrl(true);
          case "M":
            return setMsUrl(true);
          case "C":
            return setCusUrl(true);
          default:
            return setNoneUrl(true);
        }
      }

    }

  }, [authToken, dispatch]);

  useEffect(() => {
    if (activeAssetData.Base) {

      if ((activeAssetData.Base.type !== "Banner")) {
        //console.log("activeAsset>>",activeAsset);
        setActiveCategories();
        setActiveSubCategories();
        //setActiveCampaigns();
        setActiveHashtags();
      }

      if ((activeAssetData.Base.type === "Brochure" || activeAssetData.Base.type === "Video")) {
        let obj = {
          target: {
            checked: true,
          }
        }
        if (activeAssetData.Base.thumb_image !== "") {
          checkboxHandler(obj, "mobile_app");
        }
        if (activeAssetData.Base.microsite_thumb_img !== "") {
          checkboxHandler(obj, "microsite");
        }
      }
      
      if ((activeAssetData.Base.type === "Microsite")) {
        let obj = {
          target: {
            checked: true,
          }
        }
        if (activeAssetData.Base.thumb_image !== "") {
          setMobileAppCheck(true);
        }
        if (activeAssetData.Base.microsite_thumb_img !== "") {
          setMicrositeCheck(true);
        }
      }

      switch (activeAssetData.Base.url_option) {
        case "L":
          setCtaCheck(false); setLpUrl(true); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "V":
          setCtaCheck(true); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "S":
          setCtaCheck(false); setLpUrl(false); setScUrl(true); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "M":
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(true); setCusUrl(false); setNoneUrl(false);
          break;
        case "C":
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(true); setNoneUrl(false);
          break;
        default:
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(true);
          break;
      }
      if (activeAssetData.Base.form_type !== null) {
        setFormType(activeAssetData.Base.form_type);
      }

     /*  if(activeAssetData.Base.secure_url !== "") {
        setActiveAssetDefineUrl((activeAssetData.Base.secure_url).split("/").pop());
      } else */ if (activeAssetData.Base.social_posturl && activeAssetData.Base.social_posturl !== "") {
        setActiveAssetDefineUrl((activeAssetData.Base.social_posturl).split("/").pop());
      }
      //console.log("social_posturl2", activeAssetData.Base.social_posturl.split("/").pop());
    }

  }, [activeAssetData]);

  useEffect(() => {
    let t = assetLibraryHashtagList.filter(e => {
      return e.tag_name === tagName
    });
    let htags = t.map(
      ({ id: value, tag_name: label }) => ({
        value,
        label,
      })
    );
    if (htags) {
      if (!_.isEmpty(activeAssetHashtags)) {
        let arr = [...activeAssetHashtags];
        arr.push(htags);
        setActiveAssetHashtags([...activeAssetHashtags, ...htags]);
        activeAssetData.Base.internal_hashtags = activeAssetHashtags.map(({ value }) => value);
      } else {
        setActiveAssetHashtags(htags);
        activeAssetData?.Base?.internal_hashtags.push(htags[0]?.value);
      }
    }
  }, [assetLibraryHashtagList])

  // console.log("baseglobal", baseGlobalFields);
  // console.log("activeAssetHashtags", activeAssetHashtags);
  // console.log("activeAssetData.Base.internal_hashtags", activeAssetData.Base?.internal_hashtags);

  const addHashTag = () => {
    if (tagName !== "") {
      dispatch(addNewHashTag(authToken, tagName));
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  const handleFile = (val) => {
    console.log("file", val);
    var reader = new FileReader();
    //var fileType = val[0].file.type;
    //console.log("type", fileType);
    reader.addEventListener("load", function () {
      var dataUrl = reader.result;
      var videoId = "videoMain";
      const videoEl = document.createElement('video');
      videoEl.id = videoId;
      videoEl.src = dataUrl;
      var videoTagRef = videoEl;
      videoTagRef.addEventListener('loadedmetadata', function (e) {
        // console.log("file height", videoTagRef.videoHeight);
        // console.log("file width", videoTagRef.videoWidth);
        // console.log("file duration", videoTagRef.duration);

        val.height = videoTagRef.videoHeight;
        val.width = videoTagRef.videoWidth;

        setUploadedEditData([val]);
      });

    }, false);

    if (val.file) {
      reader.readAsDataURL(val.file);
    }
  }
  
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fr = new FileReader();
      
      fr.onload = function () {
        const audio = document.createElement('audio');
        audio.src = fr.result;
        
        audio.onloadedmetadata = function () {
          if (audio.duration > 5.5) {
            Toast("The audio file should be upto 5 seconds.", "E");
            fileInputRef.current.value = '';
            setAudioFile(null);
            setAudioName(null);
            setAudioDuration(null);
          } else {
            // setError(null);
            const data = {
              base64: fr.result,
              file: {
                name: file.name,
                size: file.size,
                type: file.type,
              },
            };
            console.log("Audio data:", data);
            activeAssetData.Base.reel = data
            activeAssetData.Base.audio_duration = data
            setAudioDuration(audio?.duration);
            setAudioFile(data);
            setAudioName(file.name);
          }
        };

        audio.onerror = function () {
          // setError('Failed to load audio metadata');
          setAudioFile(null);
          setAudioName(null);
        };
      };

      fr.onerror = function () {
        // setError('Failed to read file');
        setAudioFile(null);
        setAudioName(null);
      };

      fr.readAsDataURL(file);
    } else {
      // setError('No file selected');
      setAudioFile(null);
      setAudioName(null);
    }
  };

  const handleImage = (e) => {
    //console.log("e", e.target.files);
    if (e) {
      setFile(null);
      setFileName("");
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282) {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else {
            setFileName(e.target.files[0].name);
            submitedImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }

  const submitedImage = (e, path, name) => {
    // console.log("submit img", activeAssetData);
    if (activeAssetData) {
      let blob = new Blob();
      const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
      activeAssetData.Base.social_tumbnail = {
        base64: e,
        file: {
          name: fileee.name,
          size: fileee.size,
          type: fileee.type,
        },
      };
      setNewThumbImg({
        base64: e,
        file: {
          name: fileee.name,
          size: fileee.size,
          type: fileee.type,
        },
      });
      setFile(null); setFileName("");
    }
  }

  //console.log("activeasset", activeAssetData);

  //console.log("baseGlobalFields-----", baseGlobalFields);
  //console.log("activeAssetCatogries",activeAssetCatogries);
  //console.log("selectedAssetType-----", selectedAssetType.article_image);
  //console.log("activeAssetDefineUrl", activeAssetDefineUrl);
  //console.log("imgUrl",imgUrl);
  //console.log("activeAssetData", activeAssetData)
  //console.log("selectedCategories-----", selectedCategories);
  //console.log("selectedCampaigns-----", selectedCampaigns);
  //console.log("selectedHashtags-----", selectedHashtags);
  //console.log("baseUploadData----", baseUploadData);
  //console.log("selectedDefineUrl",selectedDefineUrl);
  //console.log("activeAssetCampaigns",activeAssetCampaigns);
  //console.log("activeAssetCatogries",activeAssetCatogries);
  //console.log("activeAssetHashtags",activeAssetHashtags);
  //console.log("newCategory",newCategory);

  //console.log("activethumb", activeAssetThumbnail);

  return (
    <div className="new-asset  create-content">
      {isLoading && <Loading />}
      <Row className="custom-row">
        <Col className="col-sm-12">
          <ul className="selected-asset-list">
            <li className="asset-type-img">
              {activeAssetData?.Base?.content_type !== "Microsite" ? <img
                src={(editAsset && imgUrl) ? imgUrl.article_image : selectedAssetType.article_image}
                alt={selectedAssetType.article_type}
              />
                :
                <img
                  src={activeAssetData?.Base?.c_type === "video" ? micrositeVideo : micrositeBroucher}
                  alt={selectedAssetType.article_type}
                />
              }
            </li>
            <li className="active asset">
              <img
                src={activeAssetThumbnail !== "" ? activeAssetThumbnail : ""}
                alt={activeAssetData.Base && activeAssetData.Base.content_type}
              />
            </li>
          </ul>
        </Col>
      </Row>
      <Card className="global-fields-card base">
        <Row className="asset-type">
          <Col className="col-lg-6 col-md-12 col-12">
            <Row className="">
              <Col>
                <Form onSubmit={submitHandler}>
                  {(selectedAssetType.type !== "Brochure" && selectedAssetType.type !== "Video" && selectedAssetType.type !== "Microsite") && <>
                    {activeAssetData.Base && 
                      !_.isEmpty(activeAssetData.Base.thumb_image) ? (
                      <>
                        <div className="thumbImg mb-5">
                          <img
                            src={newThumbImg ? newThumbImg?.base64 : activeAssetData?.Base?.thumb_image}
                            alt="thumbImg"
                          />
                        </div>

                        <div className="mb-5">
                          {/*  <FileInputComponent
                            labelText="Upload New Thumbnail"
                            multiple={true}
                            callbackFunction={(file_arr) => {
                              if (activeAssetData) {
                                setNewThumbImg({
                                  base64: file_arr[0].base64,
                                  file: {
                                    name: file_arr[0].name,
                                    size: file_arr[0].size,
                                    type: file_arr[0].type,
                                  },
                                });
                              }
                              // console.log(
                              //   "activeAssetData.Base.thumbImg",
                              //   activeAssetData.Base.thumbImg
                              // );
                            }}
                            // accept="image/*,.pdf,video/*" 
                            accept="image/*"
                          />
                          <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small> */}
                          <Button onClick={handleClick} className="btn submit-btn">
                            Upload Thumbnail
                          </Button>
                          <input type="file"
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                          />
                          <div>
                            <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small>
                          </div>
                          {file !== null &&
                            <div style={{ width: 600, height: 400 }} className="mt-2">
                              <CropImage file={file} onImgSubmit={(e) => submitedImage(e, "crop", "")} />
                            </div>
                          }
                        </div>
                      </>
                    ) : (
                      <div className="mb-5">
                        {/*  <FileInputComponent
                            labelText="Upload New Thumbnail"
                            multiple={true}
                            callbackFunction={(file_arr) => {
                              if (activeAssetData) {
                                setNewThumbImg({
                                  base64: file_arr[0].base64,
                                  file: {
                                    name: file_arr[0].name,
                                    size: file_arr[0].size,
                                    type: file_arr[0].type,
                                  },
                                });
                              }
                              // console.log(
                              //   "activeAssetData.Base.thumbImg",
                              //   activeAssetData.Base.thumbImg
                              // );
                            }}
                            // accept="image/*,.pdf,video/*" 
                            accept="image/*"
                          />
                          <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small> */}
                        <Button onClick={handleClick} className="btn submit-btn">
                          Upload Thumbnail
                        </Button>
                        <input type="file"
                          ref={hiddenFileInput}
                          style={{ display: 'none' }}
                          accept="image/*"
                          onChange={(e) => handleImage(e)}
                        />
                        <div>
                          <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small>
                        </div>
                        {file !== null &&
                          <div style={{ width: 600, height: 400 }} className="mt-2">
                            <CropImage file={file} onImgSubmit={(e) => submitedImage(e)} /> {/* setSubmitImage */}
                          </div>
                        }
                      </div>
                    )}
                  </>}
                  
                  { (selectedAssetType.type === "Brochure" || selectedAssetType.type === "Video"  || selectedAssetType.type === "Microsite") && (
                    <>
                      <Form.Group className="mb-4 checkbox-btn d-flex m-0">
                        <Form.Check
                          style={{ marginRight: "48px" }}
                          type="checkbox"
                          label="Mobile App"
                          name="mobile-app"
                          checked={mobileAppCheck}
                          onChange={(e) => {
                            checkboxHandler(e, "mobile_app");
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Microsite"
                          name="microsite"
                          checked={micrositeCheck}
                          onChange={(e) => {
                            checkboxHandler(e, "microsite");
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                  
                  { (selectedAssetType.type === "Brochure" || selectedAssetType.type === "Video" || selectedAssetType.type === "Microsite") && <>
                    <div className="mb-3">
                      {currentThumbnailStatus !== "none" && ((mobileAppImage === null) || (micrositeImage === null)) && <Button onClick={handleButtonClick} className="btn submit-btn">
                        Upload Thumbnail
                      </Button>}
                        <input type="file"
                          ref={hiddenFileInput}
                          style={{ display: 'none' }}
                          accept="image/*"
                          onClick={e => (e.target.value = null)}
                          onChange={(e) => handleMultipleImage(e,currentThumbnailStatus,'mobile_app')}
                        />
                        <input type="file"
                          ref={hiddenFileInput1}
                          style={{ display: 'none' }}
                          accept="image/*"
                          onClick={e => (e.target.value = null)}
                          onChange={(e) => handleMultipleImage(e,currentThumbnailStatus,"microsite")}
                        />
                        {currentThumbnailStatus !== "none" && <div>
                            {mobileAppCheck === true && mobileAppImage === null && <div style={{fontSize:"11px"}} className="text-danger small-text">Recommended size (app) - 500(W) x 282(H)</div>}
                            {micrositeCheck === true && micrositeImage === null && <div style={{fontSize:"11px"}} className="text-danger small-text">Recommended size (microsite) - 353(W) x 400(H)</div>}
                            {(selectedAssetType.type === "Brochure" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "brochure")) && <small>Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>}
                        </div>}
                        {file !== null &&
                          <div style={{ width: 600, height: 400 }} className="mt-2">
                            <CropImage file={file} nextCropper={nextCropper} cropperFlag={cropperFlag} checkPass={ (mobileAppCheck === true && micrositeCheck === true && openMicrositeCropper === true) ? "multi":"single" } thumbnailStatus={currentThumbnailStatus} onImgSubmit={(e) => submitedMultipleImage(e)} /> {/* setSubmitImage */}
                          </div>
                        }
                        {micrositeCropperData !== null && openMicrositeCropper === true &&
                          <div style={{ width: 600, height: 400 }} className="mt-2">
                            <CropImage file={micrositeCropperData} cropperFlag={cropperFlag} thumbnailStatus={currentThumbnailStatus} onImgSubmit={(e) => submitedMultipleImage(e)} /> 
                          </div>
                        }
                    </div>
                    <div className="d-flex">
                      {(activeAssetData?.Base?.thumb_image !== "" || mobileAppImage !== null) && <div className="thumbImg mb-3" style={{ marginRight: "20px" }}>
                        <div className="thumb-div">
                          <img src={mobileAppImage?.base64 || activeAssetData?.Base?.thumb_image} alt="thumbImg" />
                          {mobileAppImage !== null && <Icon.X onClick={() => {
                            setMobileAppImage(null);
                            setCurrentThumbnailStatus("mobile_app");
                          }}
                            className="close-icon-asset"
                          />}
                        </div>
                      </div>}
                      {(activeAssetData?.Base?.microsite_thumb_img !== "" || micrositeImage !== null) && <div className="thumbImg mb-3" style={{ marginRight: "20px" }}>
                        <div className="thumb-div">
                          <img src={micrositeImage?.base64 || activeAssetData?.Base?.microsite_thumb_img} alt="thumbImg" />
                          {mobileAppImage !== null && <Icon.X onClick={() => {
                            setMicrositeImage(null);
                            setCurrentThumbnailStatus("microsite");
                            setOpenMicrositeCropper(true);
                            setMicrositeCropperData(null);
                          }} className="close-icon-asset" />}
                        </div>
                      </div>}
                    </div>
                  </>}

                  {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ) ? (
                    <div className="smartFolder-multiSelect ">
                      <Form.Label htmlFor="select-platform">
                        Select Platform
                      </Form.Label>
                      <div className="url-max-size">
                        <Form.Group className="mb-4 checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="Facebook"
                            name="fb-platform"
                            checked={
                              activeAssetData.Base &&
                              _.includes(activeAssetData.Base.platform, 1)
                            }
                            onChange={(e) => {
                              platformHandler(e, "fb");
                              setPlatformFB(!platformFB);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="Twitter"
                            name="tw-platform"
                            checked={
                              activeAssetData.Base &&
                              _.includes(activeAssetData.Base.platform, 2)
                            }
                            onChange={(e) => {
                              platformHandler(e, "tw");
                              setPlatformTW(!platformTW);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="LinkedIn"
                            name="in-platform"
                            checked={
                              activeAssetData.Base &&
                              _.includes(activeAssetData.Base.platform, 3)
                            }
                            onChange={(e) => {
                              platformHandler(e, "in");
                              setPlatformLN(!platformLN);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <Form.Group className="mb-3 smartFolder-multiSelect">
                    <Form.Label className="mt-2">
                      Name <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control className="custom-input"
                      type="text"
                      ref={nameRef}
                      autoComplete="off"
                      required
                      value={activeAssetTitle}
                      onChange={(e) => {
                        setActiveAssetTitle(e.target.value);
                        activeAssetData.Base.content_title = e.target.value;
                      }}
                    />
                  </Form.Group>
                  
                  <div className="smartFolder-multiSelect">
                    <Form.Label className="mt-2">
                      Update Content
                    </Form.Label>

                    <div className="d-flex justify-content-between">
                      <Button
                        variant="info"
                        onClick={()=>{setContentUpdatePopup(true)}}
                        className="text-white button-update mt-lg-0 mr-2 mt-2"
                      >
                        <span className="mr-3">Update</span>
                        <span>
                          <Icon.Upload />
                        </span>
                      </Button>
                      <a style={{color:"#45bcd2"}} className="mt-1 text-decoration-none show-pointer" target="blank" href={main_content_link}>View Content</a>
                    </div>
                  </div>

                  {selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Microsite" ||
                    selectedAssetType.type === "Document" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic" ||
                    selectedAssetType.type === "Video" ||
                    !selectedAssetType.type === "Banner" ? (
                    <>
                      {categories && (
                        <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-2">
                            Categories
                          </Form.Label>

                          
                          <Multiselect className="custom-input"
                            options={categories}
                            selectedValues={activeAssetCatogries ? activeAssetCatogries : globalCategories}
                            onSelect={onSelectCategory}
                            onRemove={onRemoveCategory}
                            displayValue="label"
                            showCheckbox={true}
                            customArrow={<Icon.ChevronDown/>}    
                            placeholder="Selected Categories"
                            singleSelect={company_logo?.new_login != "tal" ? false : true}  
                          />

                        </div>
                      )}

                      {/* {camapigns && (
                        <div className="smartFolder-multiSelect mb-4">
                          <Form.Label className="mt-2">
                            Campaigns
                          </Form.Label>
                          <Multiselect
                            options={camapigns}
                            selectedValues={activeAssetCampaigns?activeAssetCampaigns:globalCampaigns}
                            onSelect={onSelectCampaign}
                            onRemove={onRemoveCampaign}
                            displayValue="label"
                            showCheckbox={true}
                            placeholder="Selected Campaigns"
                          />
                        </div>
                      )} */}

                      {(selectedAssetType.type !== "Banner" && selectedAssetType.type !== "Microsite" && company_logo?.isBuyNow !== 0) && (
                        <>
                            <div className="smartFolder-multiSelect mb-3">
                              <Form.Label className="mt-0">
                                Buy Now Link
                              </Form.Label>

                              <Form.Group className="mb-0 ali checkbox-btn d-flex align-item-center m-0">
                                <Form.Check
                                  type="checkbox"
                                  name="buy-now"
                                  checked={buyNowCheck}
                                  onChange={(e) => {
                                    setBuyNowCheck(e?.target?.checked)
                                    activeAssetData.Base.buy_now = e?.target?.checked !== false ? 1 : 0
                                  }}
                                />
                              </Form.Group>
                            </div>
                        </>
                      )}

                      {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                        selectedAssetType.type === "Video" ||
                        // (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video") ||
                        selectedAssetType.type === "Images" ||
                        selectedAssetType.type === "Brochure") && (
                          <>
                            {subcategories && (
                              <div className="smartFolder-multiSelect mb-3">
                                <Form.Label className="mt-2">
                                  {company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Fund Name" : "Sub-Categories") : "Product"}
                                </Form.Label>

                                
                                <Multiselect className="custom-input"
                                  options={subcategories}
                                  avoidHighlightFirstOption={true}
                                  selectedValues={activeAssetSubCatogries ? activeAssetSubCatogries : globalSubCategories}
                                  onSelect={onSelectSubCategory}
                                  onRemove={onRemoveSubCategory}
                                  displayValue="label"
                                  showCheckbox={true}
                                  customArrow={<Icon.ChevronDown/>}  
                                  singleSelect={company_logo?.new_login != "tal" ? false : true}
                                  placeholder={company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Selected Fund Name":"Selected Sub Categories"):"Selected Products"}
                                />
                              </div>
                            )}
                          </>
                        )}


                      {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                        selectedAssetType.type === "Video" ||
                        (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video") ||
                        selectedAssetType.type === "Images") && (
                          <>
                            {assetLibraryLeadGenerationList && (
                              <div className="smartFolder-multiSelect mb-4">
                                <Form.Label className="mt-2">
                                  Lead Generation
                                </Form.Label>
                                <Form.Select
                                  id="lead-genration"
                                  as="select"
                                  className="greeting mb-3 custom-input"
                                  value={activeAssetData.Base && activeAssetData.Base.lg_type}
                                  onChange={(e) => {
                                    setLeadGeneration(e.target.value);
                                    activeAssetData.Base.lg_type = e.target.value;
                                  }}
                                >
                                  <option key={1} value="">Select Lead Generation</option>
                                  {assetLibraryLeadGenerationList.map((item, index) => {
                                    return (
                                      <>
                                        <option key={index + 2} value={item.id} >{item.lg_type}</option>
                                      </>
                                    )
                                  })}
                                </Form.Select>
                              </div>
                            )}
                          </>
                          )}
                        
                        {selectedAssetType.type === "Video" && company_logo?.personalize_video === 1 && <>
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-1">
                                Personalize Video
                              </Form.Label>
                            <div className="input-form-column">
                              <Form.Select
                                className="greeting input-form text-capitalize w-100 p-2 float-unset"
                                aria-label="select-greetings"
                                style={{color:"#6e6b6b"}}
                                onChange={(e) => {
                                  setSelectedPersonalize(e.target.value);
                                  setPersonalizeType(e.target?.selectedOptions[0]?.text?.toLowerCase())
                                  activeAssetData.Base.p_type = e.target?.selectedOptions[0]?.text?.toLowerCase();
                                  activeAssetData.Base.personalize_id = e.target.value
                                }}
                                value={activeAssetData?.Base ? activeAssetData?.Base?.personalize_id : selectedPersonalize}
                              >
                                <option value="" onClick={()=>{setSelectedPersonalize(null)}}>Select Personalize Video</option>
                                {personalizeList?.preVideoList?.length > 0 &&
                                    personalizeList?.preVideoList?.map((personalise) => {
                                    let selected = false;
                                    if (activeAssetData.Base) {
                                      if ( personalise.id === parseInt(activeAssetData?.Base?.personalize_id) ) {
                                        selected = true;
                                      }
                                      return (
                                        <>
                                          <option className="text-capitalize" value={personalise.id}>
                                            {personalise.greeting_name}
                                          </option>
                                        </>
                                      );
                                    } else return "";
                                  })}
                              </Form.Select>
                              <div className="max-width-100">
                                  <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setPersonalizeModal(true) }}>Add New Type?</a>
                                  {personalizeList?.preVideoList.length > 0 && <span style={{float:"right"}} onClick={() => { setPersonalizeDeleteModal(true) }} className="delete-icon"><Icon.Trash/></span>}
                              </div>
                            </div>
                            </div>
                        
                          {((!activeAssetData?.Base?.p_type.includes("reel") || !personalizeType.includes("reel"))&&((activeAssetData?.Base?.personalize_id !== null && activeAssetData?.Base?.personalize_id !== "" && activeAssetData?.Base?.personalize_id) || (selectedPersonalize !== null && selectedPersonalize !== ""))) && <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                                Co-Branded Template
                              </Form.Label>
                            <div className="input-form-column d-flex">
                                <input className="d-none" type="file" id="actual-btn" accept="image/*" onChange={(e)=>{handlePersonalizeThumbnail(e)}} />
                                <label for="actual-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{activeAssetData?.Base?.personalize_template !== "" ? (activeAssetData?.Base?.personalize_template?.file?.name || handlePersonalizeTempName(activeAssetData?.Base?.personalize_template)) : personalizeFileName}</span></label> 
                            </div>
                          </div>}
                          {((activeAssetData?.Base?.personalize_id !== null && activeAssetData?.Base?.personalize_id !== "" && activeAssetData?.Base?.p_type.includes("reel") ) || (selectedPersonalize !== null && selectedPersonalize!== "" && personalizeType.includes("reel"))) && <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                                Upload Audio
                              </Form.Label>
                            <div className="input-form-column d-flex">
                                <input ref={fileInputRef} className="d-none" type="file" id="actual-audio-btn" accept="audio/*" onChange={handleFileChange} />
                                <label htmlFor="actual-audio-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{activeAssetData?.Base?.reel !== "" ? (activeAssetData?.Base?.reel?.file?.name || handlePersonalizeTempName(activeAssetData?.Base?.mp3)) : audioName}</span></label> 
                            </div>
                          </div>}  
                            </>
                        } 

                      {hashtags && (
                        <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-2">
                            Hashtags
                          </Form.Label>
                          <Multiselect className="custom-input"
                            options={hashtags}
                            selectedValues={activeAssetHashtags ? activeAssetHashtags : globalHashtags}
                            onSelect={onSelectHashtag}
                            onRemove={onRemoveHashtag}
                            displayValue="label"
                            showCheckbox={true}
                            placeholder="Selected Hashtags"
                          />
                          {/* <div>
                            <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setTagModal(true) }}>Add New Hashtag?</a>
                          </div> */}
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ||
                    (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video") ||
                    selectedAssetType.type === "Banner") && (
                      <div className="smartFolder-multiSelect">
                        <Form.Label className="mt-2">
                          Define URL
                        </Form.Label>
                        <br />
                        <div className="url-max-size">
                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Microsite URL"
                              name="define-url"
                              value="M"
                              checked={msUrl}
                              onClick={(e) => {
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(true);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("M");
                                activeAssetData.Base.url_option = "M";
                                activeAssetData.Base.url = micrositeUrl;
                              }}
                            />
                          </Form.Group>

                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Landingpage URL"
                              name="define-url"
                              value="L"
                              checked={lpUrl}
                              onClick={(e) => {
                                setLpUrl(true);
                                setScUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("L");
                                activeAssetData.Base.url_option = "L";
                              }}
                            />
                          </Form.Group>
                        
                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Custom URL"
                              name="define-url"
                              value="C"
                              checked={cusUrl}
                              onClick={(e) => {
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(true);
                                setNoneUrl(false);
                                setUrlOption("C");
                                activeAssetData.Base.url = "";
                                activeAssetData.Base.url_option = "C";
                              }}
                            />
                          </Form.Group>

                          {video_cta_flag === true && selectedAssetType.type === "Video" && <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="CTA"
                              name="define-url"
                              value="V"
                              checked={ctaCheck}
                              onClick={(e) => {
                                setCtaCheck(true);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("V");
                                activeAssetData.Base.url_option = "V";
                              }}
                            />
                          </Form.Group>}
                          { talic_active != "tal" && <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Showcase URL"
                              name="define-url"
                              value={"S"}
                              checked={scUrl}
                              onClick={(e) => {
                                setScUrl(true);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("S");
                                activeAssetData.Base.url_option = "S";
                              }}
                            />
                          </Form.Group>}
                          
                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="None"
                              name="define-url"
                              value="N"
                              checked={noneUrl}
                              onClick={(e) => {
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(true);
                                setUrlOption(null);
                                activeAssetData.Base.url_option = "";
                              }}
                            />
                          </Form.Group>
                          {lpUrl && (
                            <Form.Select
                              className="mb-3"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                  secureUrl =
                                  landingSecureUrl +
                                  e.target.value;
                                activeAssetData.Base.secure_url = secureUrl;
                                setActiveAssetDefineUrl(e.target.value);
                              }}
                              value={activeAssetDefineUrl}
                            >
                              <option value="">Select Landing URL</option>
                              {landingpageUrlList &&
                                landingpageUrlList.map((lp) => {
                                  return (
                                    <>
                                      <option
                                        id={lp.publish_page_id}
                                        value={lp.publish_page_name}
                                        selected={(selectedDefineUrl === lp.publish_page_name) ? true : false}
                                      >
                                        {lp.publish_page_name}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}

                          {ctaCheck && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setSelectedVideoType(e.target.value);
                                activeAssetData.Base.video_type = e.target.value
                              }}
                              value={activeAssetData?.Base?.video_type ? activeAssetData.Base.video_type : selectedVideoType}
                            >
                              <option value="">Select CTA</option>
                              {cta_asset_list &&
                                cta_asset_list.map((lp, lp_index) => {
                                  return (
                                    <>
                                      <option
                                        key={lp_index}
                                        id={lp.id}
                                        value={lp.id}
                                      >
                                        {lp.ctaName}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}

                          {scUrl && (
                            <Form.Select
                              className="mb-3"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                secureUrl =
                                  showcaseSecureUrl +
                                  e.target.value;
                                activeAssetData.Base.secure_url = secureUrl;
                                setActiveAssetDefineUrl(e.target.value);
                              }}
                              value={activeAssetDefineUrl}
                            >
                              <option value="">Select Showcase URL</option>
                              {showcaseUrlList &&
                                showcaseUrlList.map((sc) => {
                                  return (
                                    <>
                                      <option
                                        id={sc.id}
                                        value={sc.case_study_title}
                                        selected={(selectedDefineUrl === sc.case_study_title) ? true : false}
                                      >
                                        {sc.case_study_title}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}
                          {msUrl && (
                            <Form.Group className="mb-3 ms-cus-url">
                              <Form.Control
                                type="text"
                                ref={msRef}
                                autoComplete="off"
                                // required
                                value={micrositeUrl}
                                onChange={(e) => {
                                  activeAssetData.Base.secure_url = e.target.value;
                                }}
                              />
                            </Form.Group>
                          )}
                          {cusUrl && (
                            <Form.Group className="mb-3 ms-cus-url">
                              <Form.Control
                                type="text"
                                ref={cusRef}
                                autoComplete="off"
                                // required
                                //placeholder="Enter Custom URL"
                                value={contentCustomUrl}
                                onChange={(e) => {
                                  setcontentCustomUrl(e.target.value);
                                  activeAssetData.Base.secure_url = e.target.value;
                                }}
                              />
                            </Form.Group>
                          )}
                        </div>
                      </div>
                    )}


                  {ctaCheck && <div className="smartFolder-multiSelect m-lg-0 mb-3 custom-flex">
                    <Form.Label>
                      Form Type
                    </Form.Label>
                    <br />
                    <div className="url-max-size">
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Sequential Form"
                          name="Sequential_Form"
                          value="sequential_form"
                          checked={formType === "sequential_form" || activeAssetData.Base.form_type === "sequential_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                            activeAssetData.Base.form_type = e.target.value;
                          }
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Single Form"
                          name="Single_Form"
                          value="single_form"
                          checked={formType === "single_form" || activeAssetData.Base.form_type === "single_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                            activeAssetData.Base.form_type = e.target.value;
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>}

                  {(selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Microsite" ||
                    selectedAssetType.type === "Document" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic") && (
                      <>
                        <Form.Group className="caption mb-3 smartFolder-multiSelect " controlId="formTextarea">
                          <Form.Label className="mt-2">
                            Description
                          </Form.Label>
                          <Form.Control className="custom-input"
                            as="textarea"
                            rows={7}
                            ref={descRef}
                            value={
                              activeAssetData.Base &&
                              activeAssetData.Base.description
                            }
                            onChange={(e) => {
                              setActiveAssetDesc(e.target.value);
                              activeAssetData.Base.description = e.target.value;
                            }}
                          />
                        </Form.Group>

                      {(selectedAssetType.type === "Brochure" ||
                        selectedAssetType.type === "Microsite" ||
                          selectedAssetType.type === "Document" ||
                          selectedAssetType.type === "Infographics" ||
                          selectedAssetType.type === "Infographic" ||
                          !selectedAssetType.type === "Video") && (
                            <div className="smartFolder-multiSelect">
                              <Form.Label className="mt-2">
                                Content Type
                              </Form.Label>
                              <Form.Select
                                className="greeting mb-3 "
                                aria-label="select-type"
                                onChange={(e) => {
                                  setSelectedContentType(e.target.value);
                                  activeAssetData.Base.type_id = e.target.value
                                }}
                                value={activeAssetCatogries ? activeAssetData.Base.type_id : selectedContentType}
                              >
                                <option value={null}>Select Content Type</option>
                                {contentTypeList &&
                                  contentTypeList.map((c) => {
                                    let selected = false;
                                    if (activeAssetData.Base) {
                                      if (c.id === activeAssetData.Base.type_id) {
                                        selected = true;
                                      }
                                      return (
                                        <>
                                          <option value={c.id}
                                          //selected={selected}
                                          >
                                            {c.article_type}
                                          </option>
                                        </>
                                      );
                                    } else return "";
                                  })}
                              </Form.Select>
                            </div>
                          )}
                      </>
                    )}
                  {selectedAssetType.type === "Banner" && (
                    <Form.Group className="caption mb-3 smartFolder-multiSelect" controlId="formTextarea">
                      <Form.Label className="mt-2">
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={7}
                        ref={descRef}
                        value={
                          activeAssetData.Base &&
                          activeAssetData.Base.description
                        }
                        onChange={(e) => {
                          setActiveAssetDesc(e.target.value);
                          activeAssetData.Base.description = e.target.value;
                        }}
                      />
                    </Form.Group>
                  )}
                  {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                    (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video") ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Banner") && (
                      <>
                        {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image") && (
                          <>
                            {
                              <Form.Group className="caption mb-3 smartFolder-multiSelect" controlId="formTextarea">
                                <Form.Label className="mt-2">
                                  Long Caption
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={7}
                                  ref={longCaptionRef}
                                  value={
                                    activeAssetData.Base &&
                                    activeAssetData.Base.long_caption
                                  }
                                  onChange={(e) => {
                                    setActiveAssetLongDesc(e.target.value);
                                    activeAssetData.Base.long_caption = e.target.value;
                                  }}
                                />
                              </Form.Group>}
                            {(platformTW || platformLN) &&
                              (<>
                                <Form.Group className="caption mb-3 smartFolder-multiSelect" controlId="formTextarea">
                                  <Form.Label className="mt-2">
                                    Short Caption
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={7}
                                    ref={shortCaptionRef}
                                    value={
                                      activeAssetData.Base &&
                                      activeAssetData.Base.short_caption
                                    }
                                    onChange={(e) => {
                                      setActiveAssetShortDesc(e.target.value);
                                      activeAssetData.Base.short_caption = e.target.value;
                                    }}

                                  />
                                </Form.Group>
                              </>)
                            }
                          </>
                        )}
                      {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image" ||
                        //  (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video") ||
                          selectedAssetType.type === "Video") && (
                            <div className="smartFolder-multiSelect">
                              <Form.Label className="mt-2">
                                Greetings
                              </Form.Label>
                              <Form.Select
                                className="greeting "
                                aria-label="select-greetings"
                                onChange={(e) => {
                                  setSelectedGreeting(e.target.value);
                                  activeAssetData.Base.greeting_id = e.target.value
                                }}
                                value={activeAssetCatogries ? activeAssetData.Base.greeting_id : selectedGreeting}
                              >
                                <option value="empty">Select Greetings</option>
                                {greetingList &&
                                  greetingList.map((greeting) => {
                                    let selected = false;
                                    if (activeAssetData.Base) {
                                      if (
                                        greeting.greetingID ===
                                        parseInt(activeAssetData.Base.greeting_id)
                                      ) {
                                        selected = true;
                                      }
                                      return (
                                        <>
                                          <option
                                            value={greeting.greetingID}
                                            selected={selected}
                                          >
                                            {greeting.greeting_name}
                                          </option>
                                        </>
                                      );
                                    } else return "";
                                  })}
                              </Form.Select>
                            </div>
                          )}
                      </>
                    )}
                  {business_flag === true && business_need_list?.data && <div className="smartFolder-multiSelect mt-3 mb-4">
                    <Form.Label className="mt-2">
                      Business Need
                    </Form.Label>
                    <Form.Select
                      className="greeting mb-3 "
                      aria-label="select-type"
                      onChange={(e) => {
                        setSelectedBusinessType(e.target.value);
                        activeAssetData.Base.business_need = e.target.value
                      }}
                      value={selectedBusinessType}
                    >
                      <option value={null}>Select Business Need</option>
                      {business_need_list?.data &&
                        business_need_list.data.map((c, index) => {
                          let selected = false;
                          if (activeAssetData.Base) {
                            if (
                              c.id ===
                              parseInt(activeAssetData.Base.business_need)
                            ) {
                              selected = true;
                            }
                            return (
                              <>
                                <option key={index} value={c.id} selected={selected}>
                                  {c.type}
                                </option>
                              </>
                            );
                          } else return "";
                        })}
                    </Form.Select>
                  </div>}

                  <div className="custom-btn">
                    <Button
                      variant="info"
                      type="submit"
                      className="save-proceed-btn mt-lg-0 mt-2"
                    >
                      Save & Update
                      <span>
                        <Icon.ChevronRight />
                      </span>
                    </Button>
                  </div>

                </Form>
              </Col>
            </Row>
          </Col>
          <Col className="col-lg-6 col-md-12 col-12 my-lg-0 mb-3">
            {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image") && (
              <div className="post-img mb-lg-0 mb-4 pb-lg-0 pb-3">
                {(selectedAssetType.type === "Social" || selectedAssetType.type === "Image") && (
                  <img
                    src={
                      activeAssetData.Base &&
                      activeAssetData.Base.thumb_image
                    }
                    alt="img"
                  />
                )}
              </div>
            )}
            {(selectedAssetType.type === "Banner") && (
              <div className="post-img mb-lg-0 mb-4 pb-lg-0 pb-3">
                <img
                  src={
                    activeAssetData.Base &&
                    activeAssetData.Base.slider_img
                  }
                  alt="img"
                />
              </div>
            )}
            {(selectedAssetType.type === "Brochure" ||
              (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "brochure") ||
              selectedAssetType.type === "Document" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic") && (
                // <PDFViewer
                //   document={{
                //     base64: `"${activeAssetData.Base.post_image.base64}"`,
                //   }}
                // />
                <div className="position-relative"
                >
                  <div className="post-img">
                    {/* <span className="file-type-name">
                      {activeAssetData.Base &&
                        activeAssetData.Base.document.file.name}
                    </span> */}
                    <img
                      src={pdfImgUrl}
                      alt="img"
                    />
                  </div>
                  {/* <span className="file-type-icon">
                  <Icon.FilePdf />
                </span> 
                <span className="file-type-name">
                  {activeAssetData.Base &&
                    activeAssetData.Base.document.file.name}
                </span> */}
                </div>
              )}
            {(selectedAssetType.type === "Video" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video")) &&  (
              <div className="position-relative"
              >
                <div className="post-img mb-lg-0 mb-4 pb-lg-0 pb-3">
                  {/* {_.has(activeAssetData.Base, "video_url") ? (
                      <>
                        {activeAssetData.Base && activeAssetData.Base.video_url && (
                          <span className="file-type-name">
                            {activeAssetData.Base && activeAssetData.Base.video_url}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {activeAssetData.Base &&
                          activeAssetData.Base.video.file.name && (
                            <span className="file-type-name">
                              {activeAssetData.Base &&
                                activeAssetData.Base.video.file.name}
                            </span>
                          )}
                      </>
                    )} */}
                  <img
                    src={videoImgUrl}
                    alt="img"
                  />
                </div>
                {/* <span className="file-type-icon">
                  <Icon.FilePlay />
                </span> */}
              </div>
            )}
            <ul className="base-action-btn">
              {/* <li className="icon">
                <Icon.Files />
              </li> */}
              {/* <li className="icon">
                <Icon.Trash />
              </li> */}
              {/* <li className="icon">
                <Icon.Image />
              </li>
              <li className="btn">Create a Variation</li> */}
            </ul>
          </Col>
        </Row>
      </Card >

      <Modal show={tagModal} onHide={() => setTagModal(false)}>
        <Modal.Header closeButton> {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add New HashTag</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>HashTag Name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Name"
              onChange={(e) => setTagName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer> {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addHashTag()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={personalizeModal} onHide={() => setPersonalizeModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>Personalize Video Name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Personalize Video Name"
              onChange={(e) => setPersonalizeName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addPersonalizeName()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* //////////////// content update ///////////////////// */}
      <Modal show={contentUpdatePopup} onHide={() => setContentUpdatePopup(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Update Content</div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 text-center update-content">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Icon.Upload />
                </Card.Title>
                {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
                  <Card.Text>Upload creative for WhatsApp, Facebook, Twitter and LinkedIn</Card.Text>
                )}
                {(selectedAssetType.type === "Banner") && (
                  <Card.Text>Upload microsite banner related to different products and services</Card.Text>
                )}
                {(selectedAssetType.type === "Brochure" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "brochure")) && (
                  <Card.Text>Upload product-related brochure</Card.Text>
                )}
                {(selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && (
                  <Card.Text>Upload content in PDF format </Card.Text>
                )}
                {(selectedAssetType.type === "Video" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video")) && (
                  <Card.Text>Upload a video in mp4 or YouTube link format<br/>
                  <strong className="fst-italic" >Note: Please upload a single file only</strong></Card.Text>
                )}
                <div>
                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Banner") && (
                      <div className="files-upload-wrapper pdf">
                        <FileInputComponent
                          labelText=""
                          multiple={false}
                          callbackFunction={(file_arr) => {
                            setUploadedEditData(file_arr);
                          }}
                          // accept="image/*,.pdf,video/*"
                          accept="image/*"
                        />
                      </div>
                    )}
                  {(selectedAssetType.type === "Brochure" ||
                    (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "brochure") ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic") && (
                      <div className="files-upload-wrapper pdf">
                        <FileInputComponent
                          labelText=""
                          multiple={false}
                          callbackFunction={(file_arr) => {
                            setUploadedEditData(file_arr);
                          }}
                          // accept="image/*,.pdf,video/*"
                          accept=".pdf"
                        />
                      </div>
                    )}
                  {(selectedAssetType.type === "Video" || (selectedAssetType.type === "Microsite" && activeAssetData?.Base?.c_type === "video")) && (
                    <div className="files-upload-wrapper text-center video">
                      <FileInputComponent
                        className="video-format"
                        labelText=""
                        multiple={false}
                        callbackFunction={(file_arr) => {
                          //setUploadedEditData(file_arr);
                          handleFile(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept=".mp4"
                      />
                      {/* <video id="uploadedVideo">
                        <source 
                        src={_.isEmpty(uploadedEditData) ? "" : uploadedEditData[0].base64}
                        type="video/mp4" />
                      </video> */}
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => handleContentSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={personalizeDeleteModal} onHide={() => setPersonalizeDeleteModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Delete Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          {personalizeList?.preVideoList?.length > 0 && personalizeList.preVideoList.map((p_v) => {
            return (
              <>
                {p_v.deleted_by === 1 && <div className="d-flex">
                  <input
                      className="url-input"
                      type="checkbox"
                      onChange={(e) => handleUrlCheckboxChange(e,p_v)}
                      checked={selectedPersonalizeCheck.includes(p_v)}
                    />
                  <div style={{paddingLeft:"10px"}}>{ p_v.greeting_name }</div>
                </div>}
              </>
            );
          }) }
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => deletePersonalizeName()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default Edit;
