/* eslint-disable */
import {
  Col
} from "react-bootstrap";
import React from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router";


const BusinessListFilters = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="background-color-add">
        <div className="body-container container business-head">
            <Col className="col-8 crm-col">
                <nav class="d-flex gap-3 justify-content-left">
                  <span onClick={()=>{navigate("/mybusiness")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${props.pagename === "review" && "active-nav"}`}>
                    Google Reviews
                  </span>
                  {/* <span onClick={()=>{navigate("/mybusiness/report")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${props.pagename === "report" && "active-nav"}`}>
                    Report
                  </span> */}
                  <span onClick={()=>{navigate("/mybusiness/profile")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${(props.pagename === "profile" || props.pagename === "report" || props.pagename === "update") && "active-nav"}`}>
                    Profile
                  </span>
                </nav>
            </Col>
        </div>
      </div>
    </>
  );
};

export default BusinessListFilters;
