// import "./styles.css";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function DragTable(props) {
  let navigate = useNavigate();
  const [review, setReview] = useState(props.categoryData);
  
  const getChars = (str) => {
    if (str === "") {
      return;
    }
    const firstLetters = str
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    return firstLetters;
  };
  
  return (
    <div className="App">
        <div className="table borderd">
              <div
                style={{ lineHeight: "35px" }}
                className="text-capitalize"
              >
                {review && review?.map((profile, index) => (
                  <>
                    <div className="row border-bottom">
                      <div style={{ width: "30%" }} className="text-aligning d-flex gap-3 align-items-center table-name circle-div">
                        <div className="circle-title">{ getChars(`${profile.business_title}`)}</div>
                        <div className="title-main" title={profile.business_title}>{ profile.business_title }</div>
                      </div>
                      <div style={{width:"10%"}} className="adjust-width text-none date review-aligning">
                        <div className="bordering-date time-style">
                          <span className={`business-status ${profile.status != "verified"? "yellow":"blue"}`}>{profile.status}</span>
                        </div>
                      </div>
                      <div style={{width:"45%"}} className="adjust-width text-none date review-aligning">
                        <div className="bordering-date time-style">
                          {profile.address}
                        </div>
                      </div>
                      <div className="review-action-outer col d-flex justify-content-end align-items-center">
                        <div className="right-bar-icon">
                          <span className="edit-icon" onClick={()=>{navigate(`report?id=${profile.profile_id}`)}}>
                            <Icon.BarChart color={"rgba(33, 33, 33, 0.3)"}/>
                          </span>
                        </div>
                        <div className="right-bar-icon">
                          <span className="edit-icon" onClick={()=>{navigate(`update?id=${profile.profile_id}`)}}>
                             <Icon.PencilSquare color={"rgba(33, 33, 33, 0.3)"} />   
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
        </div>
    </div>
  );
}
