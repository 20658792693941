import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const CustomBarChart = (props) => {
  const data = [
    { name: "Search", value: props?.chartData?.BUSINESS_SEARCH, fill: "#1f9568" }, // Blue for Search
    { name: "Click", value: props?.chartData?.WEBSITE_CLICK, fill: "#68b2c5" },   // Red for Click
  ];
  return (
    <div style={{ textAlign: "center" }}>
      {/* Bar Chart */}
      <BarChart
        className="pt-3"
        width={550}
        height={250}
        data={data}
        layout="vertical"
        margin={{
          top: 30,
          right: 35,
          left: 0,
          bottom: 5,
        }}
        barCategoryGap="10%"
      >
        <CartesianGrid strokeDasharray="3 3" />
        
        {/* Show axes without labels */}
        <XAxis type="number" tick={false} axisLine={true} />
        <YAxis type="category" dataKey="name" tick={false} axisLine={true} />

        <Tooltip />
        
        {/* Bars with dynamic fill */}
        <Bar dataKey="value" barSize={50}>
          {data.map((entry, index) => (
            <Bar key={index} dataKey="value" fill={entry.fill} />
          ))}
          {/* <LabelList dataKey="value" position="insideRight" fill="#fff" /> */}
        </Bar>
        
      </BarChart>
      
      {/* Color Annotations */}
        <div style={{ marginBottom: "10px", display: "flex", justifyContent: "center", gap: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "20px", height: "20px", backgroundColor: "#1f9568", marginRight: "5px" }}></span>
            <span>Search</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "20px", height: "20px", backgroundColor: "#68b2c5", marginRight: "5px" }}></span>
            <span>Click</span>
          </div>
        </div>
    </div>
  );
};

export default CustomBarChart;
