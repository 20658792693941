import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileTable from './ProfileTable';
// import Pagination from "./BusinessPagination";
import * as Icon from "react-bootstrap-icons";
import Loading from '../../Loading';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'react-bootstrap';
import { fetchProfileListing } from '../../../redux/actions/businessAction';

const BusinessProfile = (props) => {
    const authToken = props.authToken;
    const dispatch = useDispatch();
    const profile_list = useSelector((state) => state.mybusiness.profile_list);
    const isLoading = useSelector((state) => state.campaign.loading);
    
    useEffect(() => {
        dispatch(fetchProfileListing(authToken));
    }, [])
    
  return (
    <>
      <div className="row campaign-row">
        <div className="col-5 p-0 d-flex align-item-center"></div>
      </div>
      <div className='direct-list'>
          {isLoading && <Loading />}
            <Row>
              <Col>
                <div className="top-heading-bar review">
                  <ul>
                    <li style={{width: "30%"}}>
                      <span>Business Name</span>
                    </li>
                    <li style={{width: "10.5%"}}>
                      <span>Status</span>
                    </li>
                    <li style={{width: "32.5%"}}>
                      <span>Address</span>
                    </li>
                    <li style={{textAlign:"right", width: "27%"}}>
                      <span>Actions</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            {profile_list?.length > 0 && <>
                <ProfileTable authToken={authToken} subPageName={props.subPageName} path={"profile"} categoryData={profile_list} />
            </>}
            {profile_list?.length === 0 && (
            <div className="no-data asset">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
            {/* {total_data > 20 && <Pagination authToken={props.authToken} timeFilter={""} startDate={""} endDate={""} total_page={ total_data } page={pageNum} currPage={props.subPageName} />} */}
      </div>
    </>
    )
}

export default BusinessProfile;