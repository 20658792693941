import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import BusinessListFilters from "./BusinessListFilters";
import ReviewList from "./ReviewList";
import Analytics from "./Analytics";
import BusinessProfile from "./BusinessProfile";
import BusinessProfileEdit from "./BusinessProfileEdit";
import { useDispatch } from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import Page from "./Page";
import "./business.css";

const BusinessMain = (props) => {
  const [sortByOption, setSortByOption] = useState("");
  
  useEffect(() => {
    props.pageTypeHandler("Business");
  }, [])
  
  const FullScreen = () => {
    if (props.subPageName === "review") {
      return <ReviewList authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    } else if (props.subPageName === "report") {
      return <Analytics authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    } else if (props.subPageName === "profile") {
      return <BusinessProfile authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    } else if (props.subPageName === "update") {
      return <BusinessProfileEdit authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    
    // else {
    //   return <SingleScreen authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>;
    // }
  }
  
  return (
    <div className="campaign">
      <BusinessListFilters
        authToken={props.authToken}
        pagename={props.subPageName}
      />
      <Page title="Campaign">
      <div className="row main-setup-container">
        <div className="col-12">
          <FullScreen/>
        </div>
      </div>
      </Page>
    </div>
  );
};

export default BusinessMain;
  