import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../../constants/ActionTypes";
import Pagination from "./BusinessPagination";
import * as Icon from "react-bootstrap-icons";
import Loading from '../../Loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { DebounceInput } from 'react-debounce-input';
import { fetchReviewListing } from '../../../redux/actions/businessAction';
import ReviewTable from './ReviewTable';

const ReviewList = (props) => {
    const authToken = props.authToken;
    const dispatch = useDispatch();
    const [timeFilter, setTimeFilter] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [searchField, setSearchField] = useState("");
    const [endDate, setEndDate] = useState("");
    let pageNum = useSelector((state) => state.mybusiness.currPage);
    let search_input = useSelector((state) => state.mybusiness.search_input);
    const review_list_record = useSelector((state) => state.mybusiness?.review_list?.data);
    const total_data = useSelector((state) => state.mybusiness?.review_list?.count);
    const isLoading = useSelector((state) => state.campaign.loading);
    
    useEffect(() => {
        dispatch(fetchReviewListing(authToken));
    }, [])
  
    useEffect(() => {
      if (timeFilter !== 4 && timeFilter !== null) {
        document.getElementById('dropdown-autoclose-outside').click();
        dispatch(fetchReviewListing(authToken, searchField, 1, timeFilter, "", ""));
      }  
    }, [timeFilter])
    
    useEffect(() => {
        dispatch(fetchReviewListing(authToken, search_input, 1, timeFilter, "", ""));
    }, [search_input])
    
  
    const handleCleanFilter = () => {
      dispatch(fetchReviewListing(authToken, searchField, 1, null, "", ""));
      setStartDate("");
      setEndDate("");
    }
  
    const handleChangeCustomDate = () => {
      let from_time = moment(startDate).format('DD-MM-YYYY')
      let to_time = moment(endDate).format('DD-MM-YYYY')
      dispatch(fetchReviewListing(authToken, searchField, pageNum, timeFilter, from_time, to_time));
      document.getElementById('dropdown-autoclose-outside').click();
    }
    
  return (
    <>
      <div className="row campaign-row justify-content-between">
        <div className="col-5 p-0 d-flex align-item-center">
          <DebounceInput
            value={searchField}
            className="search-field form-control"
            placeholder={`Search review`}
            minLength={3}
            debounceTimeout={1500}
            onChange={(e) => {
              setSearchField(e.target.value);
              dispatch({ type: types.BUSINESS_SEARCH_INPUT, payload: e.target.value });
            }}
          />
        </div>
        <div className='col-2'>
          <div className="more-filters p-0">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-autoclose-outside"
                >
                  {timeFilter === 2
                      ? "One Week"
                      : timeFilter === 3
                        ? "One Month"
                        : timeFilter === 4
                          ? "Custom Date"
                          : "Select Date Range"}
                  {timeFilter != null ? (
                    <span
                      className="clear-time"
                      onClick={() => {
                        setTimeFilter(null); handleCleanFilter();
                      }}
                    >
                      <Icon.X />
                    </span>
                  ):(
                    <span className="icon-time">
                      <Icon.Calendar2Week />
                    </span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="m-2">
                    <Form.Check
                      type="radio"
                      label="One Week"
                      name="chooseTime"
                      checked={timeFilter === 2 ? true : false}
                      onClick={() => setTimeFilter(2)}
                    />
                    <Form.Check
                      type="radio"
                      label="One Month"
                      name="chooseTime"
                      checked={timeFilter === 3 ? true : false}
                      onClick={() => setTimeFilter(3)}
                    />
                    <Form.Check
                      type="radio"
                      label="Custom Date"
                      name="chooseTime"
                      checked={timeFilter === 4 ? true : false}
                      onClick={() => setTimeFilter(4)}
                    />
                  </div>
                  {timeFilter === 4 && (
                    <>
                      <Dropdown.Item>

                        <DatePicker
                          selected={startDate}
                          onChange={setStartDate}
                          placeholderText="Select Start Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>

                        <DatePicker
                          selected={endDate}
                          onChange={setEndDate}
                          placeholderText="Select End Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />

                      </Dropdown.Item>
                      {<Dropdown.Item>
                        <Button variant="info"
                          onClick={handleChangeCustomDate}
                          style={{ width: "100%" }}>
                          Filter By Custom Date
                        </Button>
                      </Dropdown.Item>}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
          </div>
        </div>
      </div>
      <div className='direct-list'>
          {isLoading && <Loading />}
            <Row>
              <Col>
                <div className="top-heading-bar review">
                  <ul>
                    <li style={{width: "7%"}}>
                      <span>Date</span>
                    </li>
                    <li className='px-4' style={{width: "10%"}}>
                      <span>Photo</span>
                    </li>
                    <li style={{width: "20%"}}>
                      <span>Name</span>
                    </li>
                    <li style={{width: "16%"}}>
                      <span>Rating</span>
                    </li>
                    <li style={{width: "35%"}}>
                      <span>Review</span>
                    </li>
                    <li style={{textAlign:"right", width: "12%"}}>
                      <span>Actions</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            {review_list_record?.length > 0 && <>
                <ReviewTable authToken={authToken} subPageName={props.subPageName} path={"review"} categoryData={review_list_record} />
            </>}
            {review_list_record?.length === 0 && (
            <div className="no-data asset">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
            {total_data > 5 && <Pagination authToken={props.authToken} timeFilter={timeFilter} startDate={startDate} endDate={endDate} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}
      </div>
    </>
    )
}

export default ReviewList;