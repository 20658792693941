import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Dropdown, Form } from 'react-bootstrap';
import moment from 'moment';
import CustomBarChart from './CustomBarChart';
import { fetchAnalyticsData } from '../../../redux/actions/businessAction';

const Analytics = (props) => {
    const authToken = props.authToken;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [timeFilter, setTimeFilter] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const profile_report = useSelector((state) => state.mybusiness.analytics_data);
    const review_list_record = useSelector((state) => state.mybusiness?.review_list?.data);
    const review_list_count = useSelector((state) => state.mybusiness?.review_list?.count);
  
    useEffect(() => {
      if (id) {
        dispatch(fetchAnalyticsData(authToken, id, null, "", ""));
      } else {
        navigate(`/mybusiness/profile`);
      }  
    }, [id])
  
    useEffect(() => {
      if (timeFilter !== 4 && timeFilter !== null) {
        document.getElementById('dropdown-autoclose-outside').click();
        dispatch(fetchAnalyticsData(authToken, id, timeFilter, null, null));
      }  
    }, [timeFilter])
    
    const handleCleanFilter = () => {
      dispatch(fetchAnalyticsData(authToken, id, "", null, null));  
    }
  
  const handleChangeCustomDate = () => {
    debugger;
      let from_time = moment(startDate).format('DD-MM-YYYY')
      let to_time = moment(endDate).format('DD-MM-YYYY')
      dispatch(fetchAnalyticsData(authToken, id, timeFilter, from_time, to_time));
      document.getElementById('dropdown-autoclose-outside').click();
    }
    
  return (
    <>
      <div className='direct-list mt-3'>
        <div className='d-flex justify-content-between mb-4'>
          <div className='w-25'>
            <span className='cursor-pointer' onClick={()=>{navigate(`/mybusiness/profile`)}}><Icon.ChevronLeft color='#45bcd2'/> Back to profile </span>
          </div>
          <div className="more-filters p-0">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-autoclose-outside"
                >
                  {timeFilter === 2
                      ? "One Week"
                      : timeFilter === 3
                        ? "One Month"
                        : timeFilter === 4
                          ? "Custom Date"
                          : "Select Date Range"}
                  {timeFilter != null ? (
                    <span
                      className="clear-time"
                      onClick={() => {
                        setTimeFilter(null); handleCleanFilter();
                      }}
                    >
                      <Icon.X />
                    </span>
                  ):(
                    <span className="icon-time">
                      <Icon.Calendar2Week />
                    </span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="m-2">
                    <Form.Check
                      type="radio"
                      label="One Week"
                      name="chooseTime"
                      checked={timeFilter === 2 ? true : false}
                      onClick={() => setTimeFilter(2)}
                    />
                    <Form.Check
                      type="radio"
                      label="One Month"
                      name="chooseTime"
                      checked={timeFilter === 3 ? true : false}
                      onClick={() => setTimeFilter(3)}
                    />
                    <Form.Check
                      type="radio"
                      label="Custom Date"
                      name="chooseTime"
                      checked={timeFilter === 4 ? true : false}
                      onClick={() => setTimeFilter(4)}
                    />
                  </div>
                  {timeFilter === 4 && (
                    <>
                      <Dropdown.Item>

                        <DatePicker
                          selected={startDate}
                          onChange={setStartDate}
                          placeholderText="Select Start Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>

                        <DatePicker
                          selected={endDate}
                          onChange={setEndDate}
                          placeholderText="Select End Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />

                      </Dropdown.Item>
                      {<Dropdown.Item>
                        <Button variant="info"
                          onClick={handleChangeCustomDate}
                          style={{ width: "100%" }}>
                          Filter By Custom Date
                        </Button>
                      </Dropdown.Item>}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='row top-data'>  
            <div className='col-3 shadow'>
              <p>Total Searches</p>
              <h2>{ profile_report?.response_performancearr?.BUSINESS_SEARCH || 0 }</h2>  
            </div>
            <div className='col-3 shadow'>
              <p>Total Visits</p>
              <h2>{ profile_report?.response_performancearr?.PROFILE_VIEW || 0 }</h2>  
            </div>
            <div className='col-3 shadow'>
              <p>Total Calls</p>
              <h2>{profile_report?.response_performancearr?.BUSINESS_CALLS || 0}</h2>  
            </div>
            <div className='col-3 shadow'>
              <p>Total Reviews</p>
              <h2>{review_list_count}</h2>  
            </div>
          </div>
        </div>  
        
        <div className='middle-data mt-5 row'>
          <div className='footer-report-data col-7'>
            <div className='head'>
              <p>Reviews</p>
              <Icon.BoxArrowUpRight  onClick={()=>{navigate(`/mybusiness`)}} className='icon-report'/>
            </div>
            {review_list_record.slice(0, 5).map((review, rev_i) => {
              return (
                <div className='list row mx-0 my-2'>
                  <div className='col-4'>
                    <p className='report-initial-name mb-0 w-full text-capitalize'>{review.reviewer_name}</p>
                  </div>
                  <div  className='col-8'>
                    <p className='report-review review-ellipse mb-0 py-2'>{ review?.comment || <i>no review</i> }</p>
                  </div>
                </div>
              )
            })}
            
          </div>
          
          <div className='middle-right col-4'>
            <CustomBarChart chartData={ profile_report?.response_performancearr } />
          </div>
        </div>
      </div>
    </>
    )
}

export default Analytics;