// New reducers
import {
  FETCH_PROFILE_DATA,
  BUSINESS_TOGGLE_LOADING,
  FETCH_REVIEW_LIST,
  FETCH_PROFILE_LIST,
  FETCH_ANALYTICS_DATA,
  BUSINESS_SEARCH_INPUT,
  BUSINESS_CURRENT_PAGE
} from '../../constants/ActionTypes'

const initialState = {
  business_loading: false,
  profile_data: null,
  review_list: null,
  profile_list: null,
  analytics_data: null,
  search_input: "",
  currPage: 1,
}

const businessReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROFILE_DATA:
      return { ...state, profile_data: payload }
    case FETCH_ANALYTICS_DATA:
      return { ...state, analytics_data: payload }
    case BUSINESS_SEARCH_INPUT:
      return { ...state, search_input: payload };
    case FETCH_REVIEW_LIST:
      return { ...state, review_list: payload }
    case BUSINESS_CURRENT_PAGE:
      return { ...state, currPage: payload };
    case FETCH_PROFILE_LIST:
      return { ...state, profile_list: payload }
    case BUSINESS_TOGGLE_LOADING:
      return { ...state, business_loading: payload }
    default:
      return state
  }
}
export default businessReducer